import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import { Link } from "gatsby"


// markup

class IndexPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            data:[]
        }
    }


    getData = () => {
        let id = this.props.location.state.value;
        axios.get('http://localhost:1337/blogs/'+id+'', {
        })
            .then((result) => {
                // console.log(result.data)
                this.setState({
                    data: result.data
                })
            })
            .catch((error) => {
            })
    }


    componentDidMount(){
        this.getData();
    }


    render(){

        const { data } = this.state;

        // console.log(data)

        return(
            <>
                <main>
          <div className="container">
                        <div className="row">

                            {data.length == 0 ? (
                                <>
                                    <p> Loading .....  </p>
                                </>
                            ) : (
                                <>

                                    <div className="col-sm-12">
                                        <div className="card">
                                            <img className="card-img-top" src={`http://localhost:1337${data.Image.url}`} alt="Card image cap" />
                                            <div className="card-body">
                                                <h5 className="card-title">{data.Title}</h5>
                                                <p className="card-text">{data.Content}</p>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )}





                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default IndexPage
